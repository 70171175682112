var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mainContainer"},[_c('div',{staticClass:"mainContainer__container"},[_c('div',{staticClass:"mainContainer__header"},[_c('div',{staticClass:"mainContainer__project"},[_vm._v(" "+_vm._s(_vm.clientInfo['client_name'])+_vm._s(_vm.middleContent)+_vm._s(_vm.projectInfo['project_name'])+" ")]),_c('div',{staticClass:"mainContainer__titleArea"},[_c('ScreenHeader',{attrs:{"screenIcon":_vm.screenIcon,"screenName":_vm.screenName,"isShowProjectInfo":true}}),_c('Badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatusBadge),expression:"showStatusBadge"}],staticClass:"mainContainer__badgeArea",attrs:{"badgeContent":_vm.badgeContent}})],1),_c('div',{staticClass:"mainContainer__period"},[_c('ProjectDeadline',{attrs:{"startDate":_vm.schedule['estimate_start_date'],"endDate":_vm.schedule['estimate_end_date'],"isDateRange":true}})],1)]),_c('div',{staticClass:"mainContainer__body"},[_c('div',{staticClass:"contentBlock"},[_c('div',{staticClass:"contentBlock__container"},[_c('div',{staticClass:"documentShow",class:{
							'--showHistory': _vm.isRetryHistory,
						}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent && !_vm.isQuotationShow),expression:"isShowContent && !isQuotationShow"}],staticClass:"emptyDataTitle"},[_vm._v(" "+_vm._s(_vm.noDataText)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent && _vm.isQuotationShow),expression:"isShowContent && isQuotationShow"}],staticClass:"documentShow__container",on:{"mouseover":function($event){_vm.isSearchIconShow = true},"mouseleave":function($event){_vm.isSearchIconShow = false},"click":_vm.onPDFClick}},[_c('div',{staticClass:"documentShow__container__wrapper"},[_c('HxPdfExporter',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"templateName":"EstimateTemplate","fileName":_vm.defaultFileName,"pdfData":_vm.pdfData}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSearchIconShow),expression:"isSearchIconShow"}],staticClass:"documentShow__previewIcon"},[_c('img',{attrs:{"src":require("@/assets/ForComponent/SearchIcon.svg")}})])],1)]),(_vm.isRetryHistory)?_c('div',{staticClass:"documentShow__additionalInfo"},[(_vm.isRetryHistory)?_c('div',{staticClass:"documentShow__additionalInfo__container"},[_c('div',{staticClass:"documentShow__additionalInfo__container-title"},[_vm._v("履歴")]),_c('div',{staticClass:"documentShow__additionalInfo__container-itemList"},_vm._l((_vm.retryHistories),function(retryHistory,index){return _c('div',{key:index,staticClass:"documentShow__additionalInfo__container-item"},[_c('img',{attrs:{"src":require("@/assets/LeftSideBarIcon/Estimate.svg"),"alt":""}}),_c('div',{staticClass:"documentShow__additionalInfo__container-item__date",on:{"mousedown":function($event){return _vm.onHistoryClick(
													retryHistory['pdf_file_key'],
													retryHistory['confirm_time']
												)}}},[_vm._v(" "+_vm._s(_vm._f("filterHistoryDate")(retryHistory['confirm_time']))+" ")])])}),0)]):_vm._e()]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowContent && _vm.isQuotationShow),expression:"isShowContent && isQuotationShow"}],staticClass:"documentShow__buttonContainer"},[_c('button',{staticClass:"documentShow__button",on:{"click":_vm.onDownload}},[_vm._v(" ダウンロード ")])]),(_vm.isRetryHistory)?_c('div',{staticClass:"documentShow__mobileAdditionalInfo"},[_c('div',{staticClass:"documentShow__additionalInfo__container-title"},[_vm._v("履歴")]),_c('div',{staticClass:"documentShow__additionalInfo__container-itemList"},_vm._l((_vm.retryHistories),function(retryHistory,index){return _c('div',{key:index,staticClass:"documentShow__additionalInfo__container-item"},[_c('img',{attrs:{"src":require("@/assets/LeftSideBarIcon/Estimate.svg"),"alt":""}}),_c('div',{staticClass:"documentShow__additionalInfo__container-item__date",on:{"mousedown":function($event){return _vm.onHistoryClick(
											retryHistory['pdf_file_key'],
											retryHistory['confirm_time']
										)}}},[_vm._v(" "+_vm._s(_vm._f("filterHistoryDate")(retryHistory['confirm_time']))+" ")])])}),0)]):_vm._e()])])]),_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isConfirmButtonShow && !_vm.preview && !_vm.clientMode),expression:"isConfirmButtonShow && !preview && !clientMode"}],staticClass:"mainContainer__bottom",class:{
					'mainContainer__bottom--liveScreenMinimum': _vm.mobileLiveScreenMinimum,
				}},[_c('BottomButton',{attrs:{"buttonType":'multiple-btn',"screenName":"御見積書","denyButtonName":"再見積り依頼","acceptButtonName":"承 認","bottomButtonCaption":"が届いています"},on:{"on-accept-button-trigger":_vm.onAcceptButtonClick,"on-decline-button-trigger":_vm.onDeclineButtonClick}})],1)]),_c('NotificationModal',{attrs:{"plainModalId":_vm.plainModalId,"selectiveModalId":_vm.selectiveModalId,"selectiveContents":_vm.selectiveContents,"plainContents":_vm.plainContents},on:{"on-confirm-require":_vm.onConfirmYesClick,"on-declined-require":_vm.onConfirmNoClick}}),_c('Modal',{attrs:{"pdfPreviewer":_vm.pdfPreviewer,"filePreviewModalId":'preview-pdf-modal'},on:{"on-download-pdf":_vm.onDownload}}),_c('ReasonModal',{attrs:{"reasonTitle":"再見積り依頼の理由","reasonModalId":"estimate-reason-modal"},on:{"on-yes-click":_vm.onSendRetryReason}}),_c('HxPdfExporter',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],ref:"hxPdfExporterPurchaseOrder",attrs:{"templateName":"PurchaseOrderTemplate","fileName":_vm.defaultFileName,"pdfData":_vm.pdfData},on:{"on-finished-render":_vm.onRenderPurchaseOrder}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }