import ScreenHeader from '@/components/ScreenHeader/ScreenHeader.vue';
import ProjectDeadline from '@/components/ProjectDeadline/ProjectDeadline.vue';
import NotificationModal from '@/components/NotificationModal/NotificationModal.vue';
import socketClient from '@/services/SOCKET';
import HxPdfExporter from '@/components/HxPdfExporter/HxPdfExporter.vue';
import Modal from '@/components/Modal/Modal.vue';
import BottomButton from '@/components/BottomButton/BottomButton.vue';
import ReasonModal from '@/components/ReasonModal/ReasonModal.vue';
import Badge from '@/components/Badge/StatusBadge.vue';

import TransferService from '@/services/API/transfer.service';
import EstimateParticularService from '@/services/API/estimate_particular.service';
import ScheduleService from '@/services/API/schedule.service';
import { sendNotify, toLineMessageTemplate } from '@/utilities';
import updateProjectStatusMixin from '@/mixin/updateProjectStatus';

import CommonConst from '@/constants/CommonConst';
import EstimateConst from '@/constants/EstimateConst';
import EstimateMixin from '@/mixin/estimateMixin';
import redDotNotifyMixin from '../../../mixin/redDotNotifyMixin';

import moment from 'moment';
import Axios from 'axios';
import { mapState } from 'vuex';

export default {
	name: 'EstimateScreen',
	mixins: [updateProjectStatusMixin, EstimateMixin, redDotNotifyMixin],
	props: {
		mobileLiveScreenMinimum: Boolean,
	},
	components: {
		ScreenHeader,
		ProjectDeadline,
		NotificationModal,

		HxPdfExporter,
		Modal,
		BottomButton,
		ReasonModal,
		Badge,
	},
	data() {
		return {
			screenIcon: 'estimate-icon',
			screenName: '見積書作成',
			defaultFileName: '',
			selectiveContents: EstimateConst.SELECTIVE_CONTENT,
			plainContents: '',
			requirementContents: '',
			companyName: '',
			directLink: 'decision',
			estimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			// isConfirmed: false,
			isShowContent: false,
			isConfirmButtonShow: false,
			isQuotationShow: false,
			showStatusBadge: false,
			badgeContent: EstimateConst.RETRY_TEXT,
			badgeType: 'notify',
			noDataText: EstimateConst.NO_DATA_TEXT,
			pdfData: {},
			// Data using for get image and pdf file url
			pdfFileKey: '',
			pdfImageKey: '',
			defaultFilePath: 'default-template.jpeg',
			expiration: CommonConst.ONE_WEEK_SECONDS,
			pdfFileUrl: '',
			selectiveModalId: 'estimate-selective-modal',
			plainModalId: 'estimate-plain-modal',
			originEstimateData: {
				estimateParticular: {},
				estimateDetails: [],
			},
			middleContent: ' 様 ／ ',
			retryHistories: [],
			/**
			 * @binding Estimate Status
			 * @value 'onSave': When Quotation has been saved
			 * @value 'onWaitingConfirm': When Confirm Button has been clicked
			 * @value 'onDecline': When client has been decline
			 * @value 'onAccept'
			 * @value 'onFinished'
			 */
			estimateStatus: '',
			estimateParticularId: undefined,
			historyPdfData: {},
			purchaseOrderFileKey: '',
			isSearchIconShow: false,
			mailContent: '',
			pdfPreviewer: {},
			mailTitle: '',
			subject: '',
			isSendCreator: false,
		};
	},
	computed: {
		// Get projectId, userId from store
		...mapState([
			'projectId',
			'userId',
			'projectInfo',
			'clientInfo',
			'clientMode',
			'creatorInfo',
			'managementMasterInfo',
			'schedule',
			'preview',
			'appLogo',
			'appLogoPlain',
			'redDotIndexs',
		]),
		isRetryHistory() {
			return (
				this.retryHistories.length > 0 &&
				(this.estimateStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM ||
					this.estimateStatus === CommonConst.SCREEN_STATUS.DECLINE ||
					this.estimateStatus === CommonConst.SCREEN_STATUS.ACCEPT ||
					this.estimateStatus === CommonConst.SCREEN_STATUS.FINISH)
			);
		},

		mailTemplate() {
			return {
				destinations: this.isSendCreator
					? [this.creatorInfo['email_address']]
					: [this.clientInfo['email_address']],
				sender: this.managementMasterInfo['sender_email_address'],
				subject: this.subject,
				template: this.isSendCreator ? 'processmail_creator' : 'processmail_client',
				template_params: {
					projectName: this.projectInfo['project_name'],
					projectId: this.projectId,
					screenName: this.screenName,
					clientName: this.clientInfo['client_name'],
					clientId: this.clientInfo['id'],
					emailContent: this.mailContent,
					sendMailDate: moment().format('LLL'),
					appLogo: this.appLogo,
					appLogoFooter: this.appLogoPlain,
					processingScreenUrl: this.isSendCreator
						? `${this.projectInfo['process_creator_url']}estimate`
						: `${this.projectInfo['process_client_url']}estimate`,
					clientRegisterParams:
						`register_name=${this.clientInfo['client_name']}&` +
						`email_address=${this.clientInfo['email_address']}&phone_number=${this.clientInfo['phone_number']}` +
						`&account_type=client&client_id=${this.clientInfo['id']}&responsible_name=${this.clientInfo['responsible_name']}`,
				},
			};
		},
		lineMessage() {
			return toLineMessageTemplate({
				title: `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］`,
				message: this.mailContent,
				currentUrl: this.isSendCreator
					? `${this.projectInfo['process_creator_url']}estimate`
					: `${this.projectInfo['process_client_url']}estimate`,
			});
		},
		notifyContent() {
			return this.projectInfo['notify_method'] == 0
				? this.mailTemplate
				: this.projectInfo['notify_method'] == 1
				? this.lineMessage
				: {
						lineContent: this.lineMessage,
						mailContent: this.mailTemplate,
				  }; // eslint-disable-line no-mixed-spaces-and-tabs
		},
	},

	watch: {
		estimateStatus: {
			handler: function (newStatus) {
				this._checkEstimateStatus();

				// Update notification red-dot base on screen status
				if (newStatus === CommonConst.SCREEN_STATUS.WAITING_CONFIRM) {
					this.setRedDotNotiy(CommonConst.SCREEN_ID.ESTIMATE);
				} else {
					this.removeRedDotNotifyWithSocket(CommonConst.SCREEN_ID.ESTIMATE);
				}
			},
			immidiate: true,
		},
	},

	methods: {
		onAcceptButtonClick() {
			this.plainContents = EstimateConst.CONFIRM_SENT_CONTENTS;
			this.$bvModal.show(`${this.selectiveModalId}`);
		},

		onDeclineButtonClick() {
			this.$bvModal.show('estimate-reason-modal');
		},

		async onSendRetryReason(data) {
			this.$store.commit('setIsAppProcessing', true);

			this.plainContents = EstimateConst.REASON_SENT_CONTENTS;
			this.$bvModal.show(`${this.plainModalId}`);
			this.isConfirmButtonShow = false;
			this.showStatusBadge = true;

			await this._updateRetryReason(this.estimateParticularId, data);
			this.estimateStatus = CommonConst.SCREEN_STATUS.DECLINE;

			let params = {
				retryReason: data,
				estimateStatus: this.estimateStatus,
			};

			// Send event
			socketClient.send('data_transfer', {
				data: {
					user_id: this.userId,
					event_name: 'estimate_retrying_reason',
					content: params,
				},
				room: this.projectId,
			});

			// send mail to creator case 22
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］再見積り依頼が届きました`;
			this.mailContent = `再見積り依頼が\n届きました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}] 再見積り依頼が届きました`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']} 再見積り依頼を送りました`;
			this.mailContent = `再見積り依頼を\n送りました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}] 再見積り依頼を送りました`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		async onConfirmYesClick() {
			this.$store.commit('setIsAppProcessing', true);

			this.$refs.hxPdfExporterPurchaseOrder._renderTemplateString();

			let params = {
				confirm_flag: 1,
				status: CommonConst.SCREEN_STATUS.ACCEPT,
			};
			this.estimateStatus = CommonConst.SCREEN_STATUS.ACCEPT;

			await this._updateEstimateParticular(this.estimateParticularId, params);
			socketClient.send('estimate_client_confirm', {
				userId: this.userId,
				projectId: this.projectId,
				content: true,
			});

			this.$emit('on-show-download-screen-button');
			this.plainContents = EstimateConst.CONFIRM_SENT_CONTENTS;
			this.$bvModal.show(`${this.plainModalId}`);

			// send mail to creator case 20
			this.mailTitle = `[CoMoDe]［${this.clientInfo['client_name']}様 / ${this.projectInfo['project_name']}］「お見積り」確定`;
			this.mailContent = `「お見積り」が\n承認されました。`;
			this.subject = `【CoMoDe】[${this.clientInfo['client_name']}/${this.projectInfo['project_name']}]「お見積り」確定`;
			this.isSendCreator = true;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['responsible_user'],
			]);
			this.mailTitle = `[CoMoDe] ${this.projectInfo['project_name']}「お見積り」確定`;
			this.mailContent = `「お見積り」を\n承認しました。`;
			this.subject = `【CoMoDe】[${this.projectInfo['project_name']}]「お見積り」確定`;
			this.isSendCreator = false;
			sendNotify(this.notifyContent, this.projectInfo['notify_method'], [
				this.projectInfo['client_id'],
			]);

			this.$store.commit('setIsAppProcessing', false);
		},

		onConfirmNoClick() {
			this.isConfirmButtonShow = true;
		},

		/**
		 * Render Purchase Order
		 */
		async onRenderPurchaseOrder() {
			console.log('On render Purchase Order');
			let pdf = await this.$refs.hxPdfExporterPurchaseOrder.getRenderedPDFTemplate();
			let pdfFileParams = {
				content: pdf,
				project_id: this.projectId,
				file_name: 'purchase-order.pdf',
			};
			let purchaseOrderFileKey = await this._uploadPdf(pdfFileParams);
			await this._updateEstimateParticular(this.estimateParticularId, {
				purchase_order_file_key: [purchaseOrderFileKey],
			});
		},

		async _uploadPdf(params) {
			try {
				let response = await TransferService.postPdf(params);
				if (!response || response.status !== 200) {
					throw 'Upload PDF failed!';
				}

				console.log('%c Upload PDF successfully!', 'color: green');
				let key = response.data.key;
				return key;
			} catch (error) {
				console.log(error);
			}
		},

		/**
		 * Get file preview url from S3 Service
		 * @param {String} key file key from s3 service
		 * @param {Integer} expiration time of existence
		 * @returns File previrw url
		 */
		async _getPreviewPDFUrl(key, expiration) {
			try {
				let response = await TransferService.get(key, expiration);
				if (!response || response.status !== 200) {
					throw 'Get PDF url failed!';
				}

				console.log('%c Get PDF url successfully!', 'color: green');
				let url = response.data.link;
				console.log({ url });
				return url;
			} catch (error) {
				console.log(error);
			}
		},

		onPDFClick() {
			if (this.clientMode) return;
			this.pdfPreviewer.url = this.pdfFileUrl;
			this.$bvModal.show(`preview-pdf-modal`);
		},

		/**
		 * Down load history Estimate PDF file
		 * @param {String} pdfFileKey file key from S3
		 * @param {String} confirmTime Confirm time
		 */
		async onHistoryClick(pdfFileKey, confirmTime) {
			this.$store.commit('setIsAppProcessing', true);

			if (!pdfFileKey) {
				this.$store.commit('setIsAppProcessing', false);

				return;
			}

			let pdfFileUrl = await this._getPreviewPDFUrl(
				pdfFileKey,
				CommonConst.ONE_WEEK_SECONDS
			);

			let downloadDate = moment(confirmTime).format('YYYYMMDDHHmmss');
			let downloadFileName =
				this.clientInfo['client_name'] +
				'様_' +
				this.projectInfo['project_name'] +
				'_見積書_' +
				downloadDate +
				'.pdf';
			// Using Axios get PDF file to browser and rename it before downloading
			// Reason: Can not rename PDF file directly by external URL just using <a> tag
			try {
				let pdfFile = await Axios.get(pdfFileUrl, {
					headers: {
						'Content-Type': 'application/octet-stream',
					},
					responseType: 'blob',
				});
				let aTag = document.createElement('a');
				let url = window.URL.createObjectURL(pdfFile.data);
				aTag.href = url;
				aTag.download = downloadFileName;
				aTag.click();

				this.$store.commit('setIsAppProcessing', false);
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * Get estimate data
		 * Include: estimateParticular, EstimateDetails and projectName
		 */
		async _getEstimateData() {
			// Check if current project id existed
			if (this.projectId) {
				await this._getEstimateParticularAndChildrenProjectId(this.projectId);
				await this._getAllEstimateParams(this.projectId);
				await this._getPdfFileUrl(this.pdfFileKey);
			}
		},

		/**
		 * Update RetryReason
		 * @param {Integer} estimateParticularId estimateParticular ID
		 * @param {String} retryReason Reason of retrying confirmation
		 */
		async _updateRetryReason(estimateParticularId, retryReason) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: {
						retry_reason: retryReason,
						status: CommonConst.SCREEN_STATUS.DECLINE,
					},
				});
				if (response && response.status === 200) {
					console.log('%c Update retrying reason successfully!', 'color: green');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c Update retrying reason failed: ${error}`, 'color: red');
			}
		},

		/**
		 * Format Date string
		 * @param {String} date
		 * @returns Data in Japanese format 2021年9月18日
		 */
		_formatDateString(date) {
			if (date && typeof date === 'string') {
				let momentDate = moment(date);
				let year = momentDate.format('YYYY');
				let month = momentDate.format('MM');
				let day = momentDate.format('DD');

				return `${year}年${month}月${day}月`;
			}
			return date;
		},

		/**
		 * Update Estimate particular
		 * @param {Integer} estimateParticularId
		 * @param {Object} body update object
		 */
		async _updateEstimateParticular(estimateParticularId, body) {
			try {
				let response = await EstimateParticularService.update(estimateParticularId, {
					doc_content: body,
				});
				if (response && response.status === 200) {
					console.log('%c Update Estimate particular successfully!', 'color: green');
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`%c ${error}`, 'color: red');
			}
		},

		/**
		 * update Status
		 * @param {String} estimateParticularId
		 */
		async _updateEstimateStatus(estimateParticularId, status) {
			let response = await EstimateParticularService.update(estimateParticularId, {
				doc_content: { status: status },
			});
			if (response && response.status === 200) {
				return response.data;
			} else {
				// TODO: Error notification
				console.log('Update Status failed');
			}
		},

		/**
		 * Get pdf template file as url
		 * @param {String} pdfFileKey Key of the PDF template file get from pdf_file_key from record
		 */
		async _getPdfFileUrl(pdfFileKey) {
			if (pdfFileKey) {
				let pdfFileUrl = await this._getPreviewPDFUrl(pdfFileKey, this.expiration);
				this.pdfFileUrl = pdfFileUrl;
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} projectId : project id
		 */
		async _getEstimateParticularAndChildrenProjectId(projectId) {
			try {
				let response = await EstimateParticularService.get(projectId);

				if (response && response.status === 200) {
					console.log('%c Get estimate particular successfully', 'color: green');
					let data = response.data;

					if (data['estimate_particular']['status'] === 'onSave') {
						this.isShowContent = true;
						return;
					}

					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});

					if (estimateDetails.length > 0) {
						this.isQuotationShow = true;
						// this.noneObjectAlert = '';
					}
					// else {
					// 	this.noneObjectAlert = '';
					// }

					let projectName = {
						project_name: this.projectInfo['project_name'],
					};
					this.originEstimateData.estimateParticular = {
						...data['estimate_particular'],
						...projectName,
					};
					this.originEstimateData.estimateDetails = estimateDetails;
					this.pdfData = { ...this.originEstimateData };
					this.estimateParticularId = data['estimate_particular']['id'];
					this.pdfFileKey = data['estimate_particular']['pdf_file_key'];
					this.estimateStatus = data['estimate_particular']['status'];
					// Show screen content when data is loaded
					this.isShowContent = true;
				} else {
					console.log('Get estimate particular failed');
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		/**
		 * Get Estimate Particular And Children records
		 * @param {integer} estimateParticularId : Estimate Particular Id
		 */
		async _getEstimateParticularAndChildrenById(estimateParticularId) {
			try {
				let response = await EstimateParticularService.getById(estimateParticularId);

				if (response && response.status === 200) {
					let data = response.data;

					let estimateDetails = data['estimate_details'].sort((previous, current) => {
						if (previous.id < current.id) return -1;
						if (previous.id > current.id) return 1;
						return 0;
					});
					let projectName = {
						project_name: this.projectInfo['project_name'],
					};

					console.log('%c Get estimate particular successfully', 'color:green');
					return {
						estimateParticular: { ...data['estimate_particular'], ...projectName },
						estimateDetails: estimateDetails,
					};
				} else {
					throw response.message;
				}
			} catch (error) {
				console.log(`Get estimate particular failed: ${error}`);
			}
		},

		/**
		 *
		 * @param {Integer} projectId Project ID
		 * @returns Araa
		 */
		async _getAllEstimateParams(projectId) {
			try {
				let response = await EstimateParticularService.getAll(projectId);
				if (!response || response.status !== 200) {
					throw 'Get estimate particuler by project failed!';
				}

				console.log(
					'%c Get estimate particuler by project successfully!',
					'color: green'
				);
				let estimateParticulars = [...response.data];
				estimateParticulars.pop();
				this.retryHistories = estimateParticulars;
			} catch (error) {
				console.log(error);
			}
		},

		_checkEstimateStatus() {
			console.log('%c CHECK STATUS', 'color: red');
			switch (this.estimateStatus) {
				case CommonConst.SCREEN_STATUS.WAITING_CONFIRM:
					this.isConfirmButtonShow = true;
					this.showStatusBadge = false;
					break;

				case CommonConst.SCREEN_STATUS.DECLINE:
					this.isConfirmButtonShow = false;
					this.showStatusBadge = true;
					break;

				case CommonConst.SCREEN_STATUS.ACCEPT:
					this.isConfirmButtonShow = false;
					this.showStatusBadge = false;
					break;

				default:
					break;
			}
		},
		async getScheduleInfo(projectId) {
			let response = await ScheduleService.get(projectId);
			if (response && response.status === 200) {
				console.log('Get schedule successfully');
				let data = response.data;
				this.scheduleInfo = data;
				this.$store.commit('setSchedule', data);
			} else {
				console.log('Get schedule failed');
			}
		},
	},
	async created() {
		//When creator click in Confirm Button
		socketClient.listen('new_data_transfer', async (data) => {
			switch (data?.event_name) {
				case 'estimate_confirm_required':
					this.$store.commit('setIsAppProcessing', true);

					await this._getEstimateData(this.projectId);

					this.$store.commit('setIsAppProcessing', false);
					break;
				case 'client-remove-reddot':
					if (!!this.preview || this.clientMode)
						this.removeRedDotNotify(CommonConst.SCREEN_ID.ESTIMATE);
					break;
				default:
					break;
			}
		});
	},
	async mounted() {
		this.$store.commit('setIsAppProcessing', true);

		await this.getScheduleInfo(this.projectId);
		// Get Estimate Data to check initial state
		await this._getEstimateData(this.projectId);
		moment.locale('ja');

		this.$store.commit('setIsAppProcessing', false);
	},
};
